import {LifebuoyIcon} from '@heroicons/react/24/outline'

import {isCurrentNavigationItem} from '../../utils'

class NavigationItem {
    constructor(name, path, icon) {
        this.name = name
        this.path = path
        this.icon = icon
        this.current = isCurrentNavigationItem(path)
    }
}

export const navigation = [
    new NavigationItem('Contul meu', '/settings/profile', undefined)
    // new NavigationItem('Companie', '/settings/profile', undefined)
]

export const secondaryNavigation = [new NavigationItem('Asistență', '/support', LifebuoyIcon)]
