import React from 'react'
import {connect} from 'react-redux'
// @ts-ignore
import {Button} from '../../components/Button'
import {TrashIcon} from '@heroicons/react/24/outline'
import {useHistory} from 'react-router-dom'

export const SettingsProfile = ({user}: {user: any}) => {
    const history = useHistory()

    return (
        <div className="page-info">
            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mb-5">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Stergere cont</h3>
                </div>
                <div className="px-4 py-5 sm:p-6">
                    Contul tău Advocatus îți poate oferi acces la diferite aplicații din suita de aplicații oferite de
                    Advocatus.
                    <br />
                    Printre acestea, se numără: Monitor Dosare, Monitor BPI, AvoApp
                </div>
            </div>
            <div className="flex gap-5 justify-between">
                <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mb-5 w-full">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Contul tău</h3>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                        <ul>
                            <li>Email: {user.email}</li>
                            <li>Nume: {user.first_name}</li>
                            <li>Prenume: {user.last_name}</li>
                            <li>Telefon: {user.phone}</li>
                        </ul>
                    </div>
                </div>
                <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mb-5 w-full">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Aplicații la care ai acces</h3>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                        <ul>
                            <li>Monitor Dosare</li>
                            <li>Monitor BPI</li>
                            {user.has_avoapp_access && <li>AvoApp</li>}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap w-full justify-end">
                <Button
                    title="Șterge"
                    onClick={async () => {
                        history.push('/settings/profile/delete')
                    }}
                    icon={() => <TrashIcon className="h-5 w-5 mr-2" />}
                    color="red"
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    user: state.users.user
})

export default connect(mapStateToProps)(SettingsProfile)
