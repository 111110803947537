import {Form, Formik} from 'formik'
import React from 'react'
import {Link, useParams} from 'react-router-dom'

import {mapKeys, snakeCase} from 'lodash'

// @ts-ignore
import {resetPassword} from 'avoapp-react-common/dist/redux/auth'
import {connect} from 'react-redux'

// @ts-ignore
import logo from '../../assets/images/logo-advocatus.png'
// @ts-ignore
import {authSchema} from '../../assets/validations'

// @ts-ignore
import {Button} from '../../components/Button'
// @ts-ignore
import {ErrorsList} from '../../components/ErrorComponents'
// @ts-ignore
import {Input} from '../../components/Input'

import './ResetPassword.scss'

interface ResetPasswordFormValues {
    new_password1: string
    new_password2: string
    uid: string
    code: string
}

interface ResetPasswordProps {
    resetPassword: (values: ResetPasswordFormValues) => void
    isLoading: boolean
    fieldErrors: any
    nonFieldErrors: any
}

export const ResetPassword = ({fieldErrors, nonFieldErrors, isLoading, resetPassword}: ResetPasswordProps) => {
    const {resetPasswordUID, resetPasswordCode} = useParams<{resetPasswordUID: string; resetPasswordCode: string}>()

    const preparedFieldErrors = mapKeys(fieldErrors, (value, key) => snakeCase(key))

    return (
        <div className="reset-password-container">
            <div className="main-container">
                <div className="header">
                    <img
                        src={logo}
                        className="logo"
                        alt="Advocatus"
                    />
                    <h1 className="title">Resetare parolă</h1>
                </div>
                <div className="m-6 mt-0">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            new_password1: '',
                            new_password2: '',
                            uid: resetPasswordUID,
                            code: resetPasswordCode
                        }}
                        validationSchema={authSchema.resetPassword}
                        onSubmit={(values) =>
                            resetPassword({
                                new_password1: values.new_password1,
                                new_password2: values.new_password2,
                                uid: resetPasswordUID,
                                code: resetPasswordCode
                            })
                        }>
                        {({handleChange, handleBlur, values, handleSubmit, errors, touched, isValid}) => (
                            <Form className="space-y-6">
                                <Input
                                    label="Parola"
                                    value={values.new_password1}
                                    onChange={handleChange('new_password1')}
                                    onBlur={handleBlur('new_password1')}
                                    name="new_password1"
                                    errors={preparedFieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.new_password1}
                                    autoComplete="password"
                                    type="password"
                                    fullWidth
                                />
                                <Input
                                    label="Confirmare parola"
                                    value={values.new_password2}
                                    onChange={handleChange('new_password2')}
                                    autoComplete="password"
                                    type="password"
                                    name="new_password2"
                                    errors={preparedFieldErrors}
                                    fullWidth
                                />
                                <Button
                                    title="Schimbă parola"
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                    loading={isLoading}
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                    <div className="back-to-login-container">
                        <Link
                            to="/login"
                            className="back-to-login-link">
                            Înapoi la login
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    fieldErrors: state.auth.fieldErrors,
    nonFieldErrors: state.auth.nonFieldErrors,
    isLoading: state.auth.isLoading
})

const mapDispatchToProps = (dispatch: any) => ({
    resetPassword: (values: ResetPasswordFormValues) => dispatch(resetPassword(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
