import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'

import {classNames, isCurrentNavigationItem} from '../../utils'

export default function NavigationItemComponent(props) {
    const {item} = props
    const location = useLocation()

    return (
        <Link
            to={item.path}
            className={classNames(
                isCurrentNavigationItem(location, item.path)
                    ? 'bg-gray-200 text-indigo-900'
                    : 'text-neutral-800 hover:bg-gray-300',
                'group flex items-center px-2 py-2 text-md font-bold rounded-md'
            )}>
            {item.icon && (
                <item.icon
                    className="mr-3 h-6 w-6 text-indigo-900"
                    aria-hidden="true"
                />
            )}
            <p>{item.name}</p>
        </Link>
    )
}
