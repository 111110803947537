import React from 'react'

import {connect} from 'react-redux'

import {errorsModalTypes} from '../../redux/errorsModal'

import {Error500Modal, ForbiddenModal, GeneralErrorModal, NotFoundModal} from './partials'

export const ErrorsModalContainer = ({errorModalType}) => {
    switch (errorModalType) {
        case errorsModalTypes.GENERAL_ERROR:
            return <GeneralErrorModal />
        case errorsModalTypes.ERROR_500:
            return <Error500Modal />
        case errorsModalTypes.FORBIDDEN:
            return <ForbiddenModal />
        case errorsModalTypes.NOT_FOUND:
            return <NotFoundModal />
        default:
            return <React.Fragment />
    }
}

const mapStateToProps = (state) => ({
    errorModalType: state.errorsModal.type
})

export default connect(mapStateToProps)(ErrorsModalContainer)
