import React from 'react'

import {isEmpty, snakeCase, camelCase, head} from 'lodash'

import {getErrorMessage} from '../../utils/errorHelper'

import {InputError} from '../ErrorComponents'

import './Input.scss'

export default function Input({
    label,
    placeholder,
    autoComplete,
    required,
    value,
    defaultValue,
    onChange,
    onBlur,
    onClick,
    size,
    fullWidth,
    renderIcon,
    name,
    errors,
    frontendErrors,
    touched,
    type,
    min,
    max,
    disabled,
    dataTest,
    style,
    className,
    convertToSnakeCases
}) {
    const snakeCaseName = convertToSnakeCases ? snakeCase(name) : name
    const camelCaseName = camelCase(name)

    const hasErrors =
        (errors && !isEmpty(errors[snakeCaseName])) ||
        (frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))

    const chooseErrorMessageToShow = () =>
        errors && !isEmpty(errors[snakeCaseName])
            ? getErrorMessage(head(errors[snakeCaseName]), name)
            : frontendErrors[camelCaseName]

    return (
        <div className={`avo-input-label-container ${fullWidth ? 'full-width' : ''} ${className || ''}`}>
            {label && <label className="avo-label">{label}</label>}
            <div className={`avo-input-wrapper ${size || 'medium'}`}>
                {renderIcon && <div className="input-icon-wrapper">{renderIcon()}</div>}
                <input
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={onClick}
                    className={`avo-input ${renderIcon ? 'has-icon' : ''}`}
                    placeholder={placeholder}
                    autoComplete={autoComplete || 'off'}
                    required={required || false}
                    style={style}
                    min={type === 'number' ? min : undefined}
                    max={type === 'number' ? max : undefined}
                    type={type || 'text'}
                    data-test={dataTest}
                    disabled={disabled}
                />
            </div>
            {hasErrors && <InputError message={chooseErrorMessageToShow()} />}
        </div>
    )
}
